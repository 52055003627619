import React, { useMemo } from "react";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { ContentfulGeneralPageType } from "../types/contentful.types";
import ContentfulRichText from "../utilities/contentful-rich-text";
import { isMobile, isTablet } from "react-device-detect";
import AdjustLink from "./adjust-link";

// !IMPORTANT
// Background Image of the hero is coming from page template to cover all the header

const Hero = ({ data, onDownloadClick }) => {
  const { text, imageNearTheText }: ContentfulGeneralPageType["hero"] = data;

  const ctaButton = useMemo(
    () => (
      <StaticImage
        className="mt-10 glow-animation"
        src="../images/get-the-app-button.png"
        alt="Download Now"
        width={300}
      />
    ),
    []
  );

  const HeroContent = () => {
    return (
      <>
        <ContentfulRichText className="hero-title text-center px-16" raw={text} />
        {ctaButton && (
          <div className="flex items-center justify-center text-center">
            {isMobile || isTablet ? (
              <AdjustLink>{ctaButton}</AdjustLink>
            ) : (
              <button onClick={onDownloadClick}>{ctaButton}</button>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className={`hero${imageNearTheText ? " hero-image-near" : ""}`}>
      {imageNearTheText ? (
        <div className="hero-top">
          <div className="">
            <GatsbyImage
              image={
                getImage(
                  imageNearTheText.localFile.childImageSharp.gatsbyImageData
                )!
              }
              alt=""
            />
          </div>
          <div className="hero-content mb-10 xl:mb-0 md:px-10">
            <HeroContent />
          </div>
        </div>
      ) : (
        <div className="hero-content">
          <HeroContent />
        </div>
      )}
    </div>
  );
};
export default Hero;
